

#prompt-input {
  flex-grow: 1;
  padding: 1rem;
  border-radius: 5px;
  min-height: 20px;
  color: white;
  overflow: auto;
  opacity: 0.8;
}

#prompt-input:focus {
  outline: none !important;
  opacity: 1;
}
