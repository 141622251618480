
html, body, #root, .App {
  height: 100%;
}

body {
  background-color: #1c1f25;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  font-family: 'Oxygen', 'Noto Sans JP', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
}

.app-title {
  margin-bottom: 5rem;
  font-family: 'Tsukimi Rounded', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.app-title.image-bg {
  font-size: 3rem;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: url('../../assets/title_bg_1.jpeg');
  background-position: center;
  background-size: 100%;
  /* background-image: url('../../assets/title_bg_2.jpeg');
  background-position: 70% 10%;
  background-size: 150%; */
}

.bg-blob {
  position: absolute;
  top: -90px;
  width: 650px;
  opacity: 0.35;
  user-select: none;
  pointer-events: none;
  z-index: 0;
  mix-blend-mode: screen;
}

.bg-blob img {
  width: 100%;
}

.auth-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  padding: 1rem 0;
}

.auth-container > *:not(:last-child) {
  margin-bottom: 1rem;
}

.auth-title {
  margin-bottom: 1.5rem;
  font-family: 'Oxygen', sans-serif;
  font-size: 1.75rem;
  font-weight: 300;
  /* text-transform: uppercase; */
}



.chact-input {
  display: flex;
  flex-direction: column;
  font-family: 'Oxygen', sans-serif;
}

.chact-input > * {
  margin: 0;
}

.chact-input .label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.chact-input input {
  outline: none;
  padding: 0.6rem 1.25rem;
  border-radius: 2rem;
  color: #fff;
  background-color: #313547;
  font-size: 1rem;
  border: 1px solid transparent;
  transition: border 0.3s;
}

.chact-input input:focus {
  border: 1px solid #6d97e8;
}


.toolbar-top {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 10px;
  background-color: #252b3b;
}

.select-language-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0,0,0,.5);
}

.select-language {
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 300px;
  color: #fff;
  background-color: #21242b;
  z-index: 99;
}

.select-language-bg-fill {
  background-color: #30374a;
}

.hover-text {
  color: #fff;
  transition: color 0.15s;
  cursor: pointer;
}
.hover-text:hover {
  color: #abb9d0;
}

.text-faded {
  color: #abb9d0;
}

.link-text {
  text-decoration: none;
  color: #e6ba81;
  transition: color 0.15s;
  cursor: pointer;
}
.link-text:hover {
  color: #f5cd98;
}

.theme-button {
  font-size: 0.9rem;
  font-weight: 300;
}
.theme-button.large {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 300;
}
.theme-button.main-color {
  color: #fff !important;
  background-color: #3765c9;
}
.theme-button.main-color:hover {
  background-color: #4073e2;
}



/* Remove border color from the response list container */
#response-list {
  border: none;
  overflow-y: auto;
  flex: 1;
}

.chatgpt-response {
  background-color: #313749;
}

.my-question {
  background-color: #22252e;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  color: #fff;
  background-color: #383d4e;
  border-color: rgba(32,33,35,.5);
  border-radius: 5px;
  margin: 10px 200px;
}

.input-hint {
  font-size: 0.9rem;
}

.prompt-input-line {
  display: flex;
  align-items: center;
}


#submit-button {
  background: transparent;
  /* background: transparent url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='white' d='M2.01 21L23 12 2.01 3 2 10l15 2-15 2z'/></svg>") no-repeat center center; */
  color: #b3c1da;
  font-size: 1.25rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
#submit-button:hover {
  color: #96a5c1;
}

.loading-spinner {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  color: #5dc7c4;
}

#regenerate-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

#regenerate-response-button {
  color: white;
  border: none;
  background: #10A37F;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.loading {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

#model-select-container {
  margin: 10px 200px;
  color: white;
}

#model-select-container select {
  background: transparent;
  border: none;
  outline: none;
  color: white;
}

#model-select-container select option:not(:checked) {
  background: rgba(32,33,35,.8);
}

#model-select-container select option {
  background: rgba(32,33,35,.5);
}
